<template>
  <v-sheet class="mx-auto my-5" max-width="95%">
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate />
      <br />
      お客様の情報を確認中です。<br />
      処理が完了するまでお待ちください。
    </div>
    <div class="mx-auto" outlined v-if="loading === false">
      <v-card color="pink" dark>
        <v-card-title class="text-center">DGFT決済サービス</v-card-title>
        <p class="ma-5">
          決済がキャンセルされました。<br />
          ご購入を希望される方は、処方箋表示より再操作をしてください。
        </p>

        <v-btn
          class="ma-4"
          outlined
          large
          color="white"
          v-on:click="go_priscription()"
        >
          処方箋を再表示する
        </v-btn>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import { Firebase } from "../config/firebase";

export default {
  data() {
    return {
      loading: true,
      order: null,
    };
  },
  created: function () {
    this.fetchOrder();
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    go_priscription: function () {
      console.log("order:", this.order.priscription);
      this.$router.push({
        name: "prescription",
        params: { id: this.order.priscription.id },
        query: {
          patient_id: this.order.patient_id,
          patientNumber: this.order.priscription.patientNumber,
        },
      });
    },

    fetchOrder: async function () {
      this.order = await Firebase.firestore()
        .collection("Orders")
        .doc(this.id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            return doc.data();
          } else {
            throw new Error("Payment information not found for clinic.");
          }
        })
        .catch(() => {
          throw new Error(
            Date().toLocaleString("ja") +
              " [error code: 12] get payment: not found payment for clinic"
          );
        });
      this.loading = false;
    },
  },
};
</script>
